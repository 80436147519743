<template>
  <section class="SkilTest">
    <iq-card
      class="
        iq-card
        headerCardTitle
        p-3
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <div class="title">
        <i class="ri-arrow-left-s-line mr-3" @click="$router.back(-1)"></i>
        Skills certification examination
      </div>
    </iq-card>
    <iq-card
      class="
        iq-card
        headerCardTitle
        p-3
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <div class="content" v-if="$route.query.pass =='no'">
        <div>
          <img
            class="w-40"
            src="../../../assets/images/course/testFail.png"
            alt=""
          />
        </div>
        <div class="score">{{ $route.query.score }}</div>
        <div style="color: #ef5959">I'm sorry you didn't pass the exam</div>
        <div>
          <el-button type="primary" @click="$router.back(-1)"
            >Try again</el-button
          >
        </div>
      </div>
      <div class="content" v-else>
        <div>
          <img
            class="w-40"
            src="../../../assets/images/course/testPass.png"
            alt=""
          />
        </div>
        <div class="score">{{ $route.query.score }}</div>
        <div>Congratulations on passing the exam.</div>
        <div>You can apply for the certificate</div>
        <div class="certificate">
          <a href="javascript:;"> Include digital certificate </a>
          <span></span>
        </div>
        <div class="certificate" v-if="Number(this.$route.query.price) > 0">
          <a href="javascript:;">
            <u @click="$bvModal.show('payConfirm')"
              >Apply for Blockchain Certificate</u
            >
          </a>
          <span>USD $ {{ this.$route.query.price }}</span>
        </div>
      </div>
    </iq-card>
    <iq-card
      class="
        iq-card
        headerCardTitle
        p-3
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <div class="content">
        <div style="color: #50b5ff">
          <router-link :to="{ name: 'social.my-certificate' }">
            <img
              style="height: 20px"
              src="../../../assets/images/course/certificate.png"
              alt=""
            />
            <span class="ml-2">My Certificate</span>
          </router-link>
        </div>
      </div>
    </iq-card>
    <b-modal id="payConfirm" hide-footer>
      <div>Are you sure to purchase the certificate?</div>
      <div
        style="border-top: 1px solid #eee; margin-top: 20px; padding-top: 10px"
      >
        <el-button
          style="float: right; margin-left: 10px"
          type="primary"
          @click="courseCertificatePayPackage()"
        >
          Pay
        </el-button>
        <el-button
          style="float: right"
          type="info"
          @click="$bvModal.hide('payConfirm')"
          >Cancel</el-button
        >
      </div>
    </b-modal>
  </section>
</template>
<script>
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
    payCertif() {
      $bvModal.show("payConfirm");
    },
    courseCertificatePayPackage() {
      this.$http
        .courseCertificatePayPackage({
          time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
          model_id: this.$route.query.id,
        })
        .then((res) => {
          // if (res.status == 200) {
          //   if (res.data.is_jump == 1) {
          //     this.$router.push({
          //       path: `/PayMoney`,
          //       query: {
          //         type: 7,
          //         id: res.data.order_id,
          //         cType: res.data.type,
          //       },
          //     });
          //   } else if (res.data.is_jump == 0) {
          //     this.$router.push({
          //       path: `/PaySuccess`,
          //       query: {
          //         typeStatus: 1,
          //       },
          //     });
          //   }
          // }
        });
    },
  },
};
</script>
<style src='' lang='scss' scoped>
.container {
  width: 960px;
  .SkilTest {
    .headerCardTitle {
      min-height: 65px;
      .title {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
      .content {
        width: 100%;
        // margin: 0 auto;
        justify-content: center;
        div {
          display: flex;
          margin: 15px;
          justify-content: center;
        }
        .score {
          font-size: 24px;
        }
        .certificate {
          display: flex;
          justify-content: space-between;
          background: #f5fbff;
          padding: 10px;
        }
      }
    }
  }
}
</style>
